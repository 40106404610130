@use '../../styles/mixins' as mixins;

.footnotes-block {

	@include mixins.sectionStyles;

	.bnpp-container {

		&>div {

			border-bottom: solid 1px #E2E5E5;
			padding-bottom: var(--wp--preset--spacing--50);
	
			ol {
				max-width: 900px;
				margin-left: auto;
	
				li {
					font-weight: 300;
					font-size: var(--wp--preset--font-size--body-s);
					&::marker {
						content:"[" counter(list-item)"] ";
					}
				}
			}

		}

	}

}